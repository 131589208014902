// Função para abrir a modal de loading global
export function showGlobalLoadingModal() {
    const modal = document.getElementById('globalLoadingModal');
    if (modal) {
        modal.classList.remove('hidden');
    }
}

// Função para fechar a modal de loading global
export function hideGlobalLoadingModal() {
    const modal = document.getElementById('globalLoadingModal');
    if (modal) {
        modal.classList.add('hidden');
    }
}

// Função para adicionar evento ao botão de cancelar
export function attachGlobalLoadingCancelEvent() {
    const cancelButton = document.getElementById('cancelGlobalLoadingButton');
    if (cancelButton) {
        cancelButton.addEventListener('click', hideGlobalLoadingModal);
    }
}

// Inicializa o evento no botão de cancelar ao carregar o DOM
document.addEventListener("DOMContentLoaded", attachGlobalLoadingCancelEvent);
